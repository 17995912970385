@use '@angular/cdk';
@use '@angular/material' as mat;
@import './app/variables/default/variables.scss';
@import '@angular/cdk/overlay-prebuilt.css';

@include cdk.text-field-autofill();
@include mat.core();

$primary-palette: (
 50: #ffebee,
 100: #ffccd1,
 200: #fb9996,
 300: #f46f6d,
 400: #ff4b46,
 500: #ff3a28,
 600: #f42f29,
 700: #e22224, 
 800: #d41a1d, 
 900: #c5060f, 
 contrast: (
   50: #444,
   100: #444,
   200: #444,
   300: #444,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white,
 )
);

$my-primary: mat.define-palette($primary-palette, 300);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

// Emit styles for MatSlider based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `slider-theme` will not
// emit any typography styles.
@include mat.slider-theme($my-theme);

// Include the theme mixins for other components you use here.

body {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-display: swap;
    font-size: 14px;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $secondary;
    position: relative;

    * {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    app-info-dialog {
        display: none;
    }

    .carousel-container {
        padding: 0 ;
    }

    .banner-container {
        margin-bottom: 0 ;
    }

    .slides-container {
        .carousel-slide {
            border-radius: 16px ;
            overflow: hidden ;
            height: 95% ;

            .photo {
                width: 100% ;
                max-height: 100% ;
            }
        }
    }
    
    .bullets-container {
        margin-top: .3rem ;

        .bullet {
            width: 20px;
            height: 5px;
            border-radius: 2.5px;

            &.current {
                background: $primary;
            }
        }
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    h2, h3 {
        font-weight: 900;
        font-size: 20px;
    }
   
    h3 {
        margin-bottom: 0;
    }

    h5 {
        font-size: 1em;
    }

    .left-col {
        display: none;
    }

    .right-col {
        background: none;
        width: 100%;
        position: relative;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        //max-width: 550px;
        margin: 0 auto;
        //padding: 0 $padding;
        
        fieldset {
            padding: 0;
        }

        label {
            font-size: 1.2em;
            padding-right: 10px;
            color: #fff;
            font-weight: 900;
            margin-bottom: 0;

            &.sublabel {
                font-weight: 400;
            }
        }

        input {
            height: 48px;
            width: 100%;
            border-radius: 4px;
            border: none;
            margin: 8px 0;
            font-family: "Montserrat", Arial, Helvetica, sans-serif;
            font-weight: 500;
            color: $secondary;
            background-color: $input-background;

            &::placeholder {
                color: $secondary;
            }
        }
      
        // fieldset {
        //     border: none;
        //     width: 100%;

        //     &:first-child {
        //         display: flex;
        //         flex-direction: column;
        //     }

            .fields {
                margin-top: 16px;
                display: flex;
                width: 100%;
                
                & > * {
                    flex: 1 1 auto;
                }
                .field {
                    display: flex;
                    flex-direction: column;
                    margin-right: 8px;
                    
                    &:first-child {
                        width: 30%;
                    }
                    &:last-child {
                        width: 30%;
                        margin-right: 0;
                    }
                }
            }

            select {
                .month {
                    text-transform: capitalize;
                }
            }
        // }

        select {
            height: 48px;
            width: 100%;
            font-size: 0.9rem;
            margin: 8px 32px 16px 0;
            border: none;
            border-radius: 4px;
            background-color: $select-background;
            color: $secondary;
            padding: 2px 5px;
            padding-right: 8px;
        
            &:focus {
                border: 2px solid $primary;
            }
        }

        input[type="text"] {
            background-color: $input-background;
        }

        input.invalid {
            color: $warn;
            border: 2px solid $warn;
        }

        .tip {
            font-size: 12px;
            margin: 4px 0;
        }
    }

    .input-holder {
        width: 100%;
    }

    button {
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
    }

    button:not(.text-link) {
        width: 100%;
    }
    
    .submit-btn-container {
        // position: absolute;
        // width: 100%;
        display: flex;
        justify-content: center;
        //bottom: 75px;
        padding: 0 16px;
    }

    button.submit {
        background: linear-gradient(90deg, $gradient-light 0%, $gradient-dark 100%);
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 340px;
        margin: 24px auto 0;

        .arrow-right {
            margin-left: 4px;
        }
    }
    button.submit:disabled {
        color: $disabled-medium;
        border: 2px solid $disabled;
        background: none;
        cursor: default;
    }
    button.text-btn, button.submit {
        height: 48px;
        border-radius: 24px;
        box-shadow: 0px 0px 16px 0px #0000001A;
    }
    button.text-btn {
        background: none;
        border: 2px solid $primary;
        color: $primary;
    }
    button.text-link {
        color: $secondary;
        border: none;
        border-bottom: 1px solid $secondary;
        background: none;
        width: fit-content;
        padding: 0;
        margin: 16px auto;
        font-size: 14px;
    }
 
    .back-link {
        height: auto;
        width: 32px;
        margin: 24px;
        cursor: pointer;
    }

    ::placeholder {
        color: $input-placeholder;
    }

    .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
        color: $primary
    }

    /* à supprimer : pour les tests */
    .delete-photo, .log-out {
        max-width: 200px;
        margin: 8px;
    }
}

@media screen and (min-width: 1280px) {
    $width: 50%;

    body {
        .left-col {
            display: block;
            background: url(../src/assets/bg_desktop.webp) top center/cover no-repeat;
            background-position: -80px;
            width: $width;  
            color: #fff;
            padding-top: 24px;
            padding-left: 112px;

            .content {
                line-height: 30px;
            }          
        }
       
        .right-col {
            background: none;
            width: 50%;

            .content {
                width: 50%;
                margin: auto;
            }
        }

        form {
            label {
                font-size: 20px;
            }

            input {
                background-color: $input-background;
            }
        }

        button.submit {
            position: initial;
            bottom: 0;
            width: 100%;
        }
    }
}

html, body { height: 100%; }
