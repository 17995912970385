$_pink: #F4706D;
$_pink-light: #ee8383;
$_pink-lighter: rgba(238, 131, 131, .5);
$_white-gloss: #FFEEEE;
$_grey-lighter: #f5f5f5;
$_grey-light: #e5e5e5;
$_grey-medium: #d5d5d5;
$_grey-dark: #999;
$_grey-darker: #444;
$_grey-darkest: #272727;
$_grey-neutral: #707070;
$_red: #D32F2F;
$_light-pink-gradient: #F9847E;
$_dark-pink-gradient: #F05B5B;
$_yellow: #FFC46A;

$primary: $_pink;
$secondary: $_grey-darker;
$warn: $_red;
$gradient-light: $_light-pink-gradient;
$gradient-dark: $_dark-pink-gradient;
$error-dialog-background: $_white-gloss;
$card-shadow: $_white-gloss;
$input-background: $_grey-lighter;
$input-placeholder: $_grey-dark;
$select-background: $_grey-lighter;
$textarea-background: $_grey-lighter;
$range-input-background-light: $_grey-light; // voir pour fusionner avec range-input-background
$checkbox-input-label: $_grey-lighter;
$radio-input-background: $_grey-lighter;
$disabled: $_grey-lighter;
$disabled-medium: $_grey-medium; // voir pour fusionner avec disabled
$text-disabled: $_grey-medium;
$palette-color-border: $_grey-light;
$palette-color-selected: $_grey-lighter;
$scrollbar-secondary: $_grey-lighter;
$scrollbar-primary: $_grey-medium;
$close-button: $_grey-medium;
$close-button-background: $_grey-neutral;
$profile-description: $_grey-neutral;
$range-input-background: $_grey-medium;
$hobby-card-background: $_grey-light;
$nav-item: $_grey-light;
$no-image-background: $_grey-lighter;
$progress-bar-step-inactive: $_grey-lighter;
$conversation-list-border: $_grey-medium;
$conversation-border: $_grey-medium;
$talk-border: $_grey-lighter;
$talk-last-message: $_grey-dark;
$talk-creation-date: $_grey-medium;
$talk_warning: $_yellow;
$back-arrow: $_grey-medium;
$notifications-border: $_grey-dark;
$notifications-separator: $_grey-lighter;
$notifications-creation-date: $_grey-medium;
$story-player-close-button: $_grey-darker;
$loader-light: $_pink-lighter;
$user-icon-background: $_grey-medium;
$story-player-background: $_grey-darkest;
$overlay: $_grey-darker;
$user-tiny-menu: $_grey-medium;

$medium: 656px;
$large: 1024px;
$padding: 24px;
$story-card-width: 160px;
$story-card-ratio: 1.4;
